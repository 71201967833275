import './Footer.css';

const Footer = () => {
    const year = new Date().getFullYear();
    return (
        <>
            <footer>
                <div className={"footer"}>
                    <div className={"row"}>Copyright &copy; {year} charon.dev - All rights reserved</div>
                </div>
            </footer>
        </>
    )
};

export default Footer;