import {Entity, Column, PrimaryGeneratedColumn} from "typeorm";

@Entity()
class User {
    @PrimaryGeneratedColumn()
    id: number;
    @Column({length: 16, type: "text"})
    name: string;
    @Column("text")
    email: string;
    @Column("text")
    password: string;
}

export default User;