import React from "react";

import './FormButton.css';
import './LoginForm.css';

import {createConnection} from "typeorm";
import User from "../entity/User";
import 'reflect-metadata';
import { useNavigate } from "react-router-dom";

function LoginForm() {

    document.addEventListener("keydown", function (event) {
        if (event.key === "Enter") {
            Login(document.getElementById("username"), document.getElementById("password"));
        } else if (event.key === "Escape") {
            document.getElementById("login")!.style.display = "none";
        }
    });

    return (
        <>
            <form id={"login"}>
                <h3>Login</h3>

                <label className={"username"}>Username</label>
                <input type={"text"} id={"username"} placeholder={"E-Mail or Username"}/>
                <label className={"password"}>Password</label>
                <input type={"password"} id={"password"} placeholder={"Password"}/>

                <button className="form-button" onClick={() => {
                    Login(document.getElementById("username"), document.getElementById("password"))
                }}>Login
                </button>

            </form>
        </>

    );
}

function Login(username: HTMLElement | null, password: HTMLElement | null) {

    const usernameValue = (username as HTMLInputElement).value;
    const passwordValue = (password as HTMLInputElement).value;

    const navigate = useNavigate();

    if (usernameValue === "" || passwordValue === "") {
        alert("Please enter a username and password!");
        navigate("/login", {replace: true});
        return;
    }

    createConnection({
        type: "mysql",
        host: "localhost",
        port: 3306,
        username: "root",
        password: "",
        database: "web",
        entities: [
            __dirname + "/entity/*.tsx"
        ],
        synchronize: true,
        logging: false
    }).then(async connection => {
        let user = new User();
        if (usernameValue.toString().includes("@")) {
            user.email = usernameValue;
        } else {
            user.name = usernameValue;
        }
        user.password = passwordValue;

        const userRepo = connection.getRepository(User).find({where: {name: user.name, password: user.password}});
        if (userRepo !== null) {
            alert("You are logged in!");
            console.log("User has been saved: ", user);

        } else {
            alert("Wrong username or password!");
            console.log("User has been saved12: ", user);

        }

        alert("Wrong username or password!");

    }).catch(error => console.log(error));

    return (
        <LoginForm/>
    );
}


export default LoginForm;