import React, {ReactNode, useState} from 'react';
import ParticlesContainer from "../particles/ParticlesContainer";
import Footer from "../Footer";
import {TypeAnimation} from "react-type-animation";
import LoginForm from "../login/LoginForm";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGithub, faTwitch} from "@fortawesome/free-brands-svg-icons";
import avatar from "../assets/avatar.png";
import '../App.css';

interface Props {
    children?: ReactNode
}

const charon = {
    name: "charon",
    image: avatar,
    imageSize: 128,
    about: "Software Developer\n",
    twitch: "https://www.twitch.tv/charonxyz",
    github: "https://www.github.com/charonxyz"
};


const TypeAnimationComponent = () => {
    return (
        <TypeAnimation
            sequence={[
                'Software Developer',
                1000, // Waits 1s
                'Always learning new things',
                1000
            ]}
            wrapper="span"
            cursor={true}
            repeat={Infinity}
            speed={50}
            style={{fontSize: '1em', display: 'inline-block'}}
        />
    );
}

const LoginButton = () => {
    const [enableForm, setEnabled] = useState(false);

    return (
        <>
            <button className="login-button"
                    onClick={() => {
                        setEnabled(!enableForm)
                    }}> Login
            </button>
            {enableForm && <LoginForm/>}
        </>
    );
}

function AboutPage() {
    return (
        <>
            <div className={"background-text"}>
                <img className="App-logo" src={charon.image} alt={'Avatar'}
                     style={{width: charon.imageSize, height: charon.imageSize}}/>
                <h2>{charon.name}</h2>

                <TypeAnimationComponent/>
                <p><i><small>go beyond your limits</small></i></p>

                <div className="buttons">
                    <button className="button"
                            onClick={() => window.open(charon.twitch, "twitch")}><FontAwesomeIcon
                        icon={faTwitch}/>
                    </button>
                    <button className="button"
                            onClick={() => window.open(charon.github, "github")}><FontAwesomeIcon
                        icon={faGithub}/>
                    </button>
                </div>
            </div>
        </>
    );
}

export default function Dashboard({children}: Props) {
    const auth = false;
    return auth ? <>{children}</> : <>
        <div className="App">
            <header className="App-header">
                <ParticlesContainer/>
                <AboutPage/>
            </header>

            <Footer/>
        </div>


        <div className={"login"}>
            <LoginButton/>
        </div>
    </>;
}