import React, { useState, useEffect } from 'react';
import axios from 'axios';

// Definiere den Benutzer-Typ
interface User {
    uuid: string;
    rank: string;
    whitelisted: boolean;
}

const Users: React.FC = () => {
    const [users, setUsers] = useState<User[]>([]);

    useEffect(() => {
        axios.get('http://localhost:1870/users')
            .then(response => {
                setUsers(response.data);
            })
            .catch(error => {
                console.error('There was an error fetching the users!', error);
            });
    }, []);

    return (
        <div>
            <ul>
                {users.map(user => (
                    <li key={user.uuid}>{user.rank}</li>
                ))}
            </ul>
        </div>
    );
};

export default Users;
