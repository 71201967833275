import React, {PureComponent} from "react";
import Particles from "react-tsparticles";

import type {Engine} from "tsparticles-engine";
import {ISourceOptions} from "tsparticles-engine";
import {loadFull} from "tsparticles";
import "./ParticlesContainer.css"

export class ParticlesContainer extends PureComponent<unknown> {
    async customInit(engine: Engine): Promise<void> {
        await loadFull(engine);
    }

    render() {
        const options: ISourceOptions = {
            fps_limit: 60,
            interactivity: {
                detect_on: "canvas",
                events: {
                    onclick: {enable: false, mode: "push"},
                    onhover: {
                        enable: true,
                        mode: "attract",
                        parallax: {enable: false, force: 60, smooth: 10}
                    },
                    resize: false
                },
                modes: {
                    push: {quantity: 1},
                    attract: {distance: 200, duration: 0.3, factor: 5}
                }
            },
            particles: {
                color: {value: "#ffffff"},
                line_linked: {
                    color: "#ffffff",
                    distance: 150,
                    enable: true,
                    opacity: 0.4,
                    width: 1
                },
                move: {
                    attract: {enable: false, rotateX: 600, rotateY: 1200},
                    bounce: false,
                    direction: "none",
                    enable: true,
                    out_mode: "out",
                    random: false,
                    speed: 2,
                    straight: false
                },
                number: {density: {enable: true, value_area: 800}, value: 50},
                opacity: {
                    anim: {enable: false, opacity_min: 0.1, speed: 1, sync: false},
                    random: false,
                    value: 0.5
                },
                shape: {
                    character: {
                        fill: false,
                        font: "Verdana",
                        style: "",
                        value: "*",
                        weight: "400"
                    },
                    image: {
                        height: 100,
                        replace_color: true,
                        src: "images/github.svg",
                        width: 100
                    },
                    polygon: {nb_sides: 5},
                    stroke: {color: "#000000", width: 0},
                    type: "circle"
                },
                size: {
                    anim: {enable: false, size_min: 0.1, speed: 40, sync: false},
                    random: true,
                    value: 3
                }
            },
            polygon: {
                draw: {enable: false, lineColor: "#ffffff", lineWidth: 0.5},
                move: {radius: 10},
                scale: 1,
                type: "none",
                url: ""
            },
            retina_detect: true
        };

        return (
            <div id={"tsParticles"}>
                <Particles options={options} init={this.customInit}/>
            </div>
        );
    }
}

export default ParticlesContainer;