import { Link } from "react-router-dom";
import {ReactNode} from "react";

interface Props {
    children?: ReactNode
}

export default function NotFound({ children }: Props) {
    return (
        <div>
            <h1>Oops! You seem to be lost.</h1>
            <p>Here are some helpful links:</p>
            <Link to='/'>Home</Link>
        </div>
    )
}